<template>
    <div class="sld_auto_reply">
        <div class="top_header">
            <p class="header_title">{{L['欢迎语']}}</p>
            <button @click="openAdd">{{L['添加欢迎语']}}</button>
        </div>
        <!-- <div class="top_bar">
            <div class="number_title">序号</div>
            <div class="reply_title">欢迎语</div>
            <div class="sort_title">排序</div>
            <div class="default_use_title">默认使用</div>
            <div class="operate_title">操作</div>
        </div>
        <div class="container" v-if="autoReplyList&&autoReplyList.list&&autoReplyList.list.length>0">
            <el-scrollbar>
                <div class="con_item" v-for="(autoItem,autoIndex) in autoReplyList.list" :key="autoIndex">
                    <div class="number">{{autoIndex+1}}</div>
                    <div class="content">{{autoItem.msgContent}}</div>
                    <div class="sort">{{autoItem.sort}}</div>
                    <div class="default_use">
                        <div class="switch_wrap" @click="operateContent(1,autoItem)">
                            <el-switch v-model="autoItem.isShow">
                            </el-switch>
                        </div>
                    </div>
                    <div class="operate">
                        <button @click="openEdit(autoItem)">编辑</button>
                        <el-popconfirm title="这是一段内容确定删除吗？" @confirm="operateContent(0,autoItem)" confirmButtonText='确定'
                            cancelButtonText='取消'>
                            <template #reference>
                                <button>删除</button>
                            </template>
                        </el-popconfirm>
                    </div>
                </div>
            </el-scrollbar>
        </div>
        <div class="empty_auto" v-if="!autoReplyList.list.length>0&&firstLoading">
            <img src="@/assets/goods/empty_data.png" alt="">
            <p>暂无欢迎语~</p>
        </div> -->
        <!-- asdasd -->


        <el-table :data="autoReplyList.list" :row-class-name="tableRowClassName">
            <el-table-column type="index" width="120" :label="L['序号']">
            </el-table-column>
            <el-table-column prop="msgContent" :label="L['欢迎语']" width="680">
            </el-table-column>

            <el-table-column prop="sort" :label="L['排序']" width="250">
            </el-table-column>
            <el-table-column :label="L['默认使用']" width="250">
                <template #default="scope">
                    <div class="switch_wrap" @click="operateContent(1,scope.row)">
                        <el-switch v-model="scope.row.isShow"></el-switch>
                    </div>
                </template>
            </el-table-column>
            <el-table-column :label="L['操作']" width="300">
                <template #default="scope">
                    <button @click="openEdit(scope.row)">{{L['编辑']}}</button>
                    <el-popconfirm :title="L['确定删除此内容吗？']" @confirm="operateContent(0,scope.row)"
                        :confirmButtonText="L['确定']" :cancelButtonText="L['取消']">
                        <template #reference>
                            <button>{{L['删除']}}</button>
                        </template>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <div class="empty_auto" v-if="!autoReplyList.list.length>0&&firstLoading">
            <img src="@/assets/goods/empty_data.png" alt="">
            <p>暂无欢迎语~</p>
        </div>


        <el-dialog :title="dialogTitle" v-model="dialogVisible" width="32%" lock-scroll="false" top="25vh"
            @closed="dialogClosed">
            <table id="popTable">
                <tr class="row1">
                    <td class="title" valign="top"><i>*</i><span>{{L['欢迎语']}}：</span></td>
                    <td>
                        <el-input type="textarea" :rows="4" :placeholder="L['请输入欢迎语内容']" v-model="autoReplyContent"
                            resize="none" maxlength="50" show-word-limit @input="watchContent(0)"
                            style="overflow:hidden">
                        </el-input>
                        <span v-show="contentMessage" class="message">{{contentMessage}}</span>
                    </td>

                </tr>
                <tr class="row2">
                    <td></td>
                    <td class="tip"><span>{{L['最多输入50字']}}</span></td>
                </tr>
                <tr class="row3">
                    <td class="title" valign="top"><i>*</i><span>{{L['输入排序']}}：</span></td>
                    <td>
                        <el-input :placeholder="L['请输入排序']" v-model="replySort" maxlength="3" show-word-limit
                            @input="watchContent(1)"></el-input>
                        <span v-show=" sortMessage" class="message">{{sortMessage}}</span>
                    </td>

                </tr>
                <tr>
                    <td></td>
                    <td class="tip"><span>{{L['请输入0~255的数字，数值越小，显示越靠前']}}</span></td>
                </tr>
                <tr class="row4">
                    <td style="text-align: right;"><span>{{L['启用']}}：</span></td>
                    <td>
                        <el-switch v-model="switchFlag"></el-switch>
                    </td>
                </tr>

            </table>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogVisible = false">{{L['取 消']}}</el-button>
                    <el-button type="primary" @click="confirm">{{L['确 定']}}</el-button>
                </span>
            </template>
        </el-dialog>

        <div class="sld_pagination">
            <el-pagination @prev-click="handlePrevCilickChange" @next-click="handleNextCilickChange"
                @current-change="handleCurrentChange" :currentPage="page.current" :page-size="page.pageSize"
                layout="prev, pager, next, jumper" :total="page.total" :hide-on-single-page="true">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import { ref, getCurrentInstance, reactive, onMounted } from 'vue'
    import { ElMessage } from 'element-plus'
    export default {
        name: 'autoReply',
        setup() {
            const msgId = ref('')//全局内容ID
            const addOrEdit = ref(0)
            const autoReplyContent = ref('')//欢迎语内容
            const replySort = ref('')//回复语排序
            const dialogVisible = ref(false)//对话框显示flag
            const page = reactive({//页数
                current: '',
                pageSize: '',
                total: ''
            })
            const identity = localStorage.getItem('identity')
            const dialogTitle = ref('')
            const autoReplyList = reactive({ list: [] })//欢迎语列表
            const params = reactive({ current: 1 })
            const switchFlag = ref(false)
            const { proxy } = getCurrentInstance()
            const contentMessage = ref('')
            const sortMessage = ref('')
            const firstLoading = ref(false)
            const L = proxy.$getCurLanguage()
            const isPreventClick = ref(false)
            //获取欢迎语
            const getAutoReplyList = () => {
                proxy.$get(`v3/helpdesk/${identity}/auto/list`, params).then(res => {
                    if (res.state == 200) {
                        autoReplyList.list = res.data.list
                        page.pageSize = res.data.pagination.pageSize
                        page.total = res.data.pagination.total
                        page.current = res.data.pagination.current
                        autoReplyList.list.map(item => {
                            item.isShow = item.isShow ? true : false
                        })
                        firstLoading.value = true
                    }
                })
            }

            //页数改变方法--start
            const handlePrevCilickChange = (e) => {
                params.current = e
                getAutoReplyList()
            }

            const handleNextCilickChange = (e) => {
                params.current = e
                getAutoReplyList()
            }

            const handleCurrentChange = (e) => {
                params.current = e
                getAutoReplyList()
            }
            //页数改变方法--end

            //确认start
            const confirm = () => {

                if (isPreventClick.value) {
                    return
                }
                isPreventClick.value = true

                let num = /^[0-9]+[0-9]*]*$/
                if (!autoReplyContent.value) {
                    contentMessage.value = L["不能为空"]
                    isPreventClick.value = false
                    return
                } else if (Number(replySort.value) > 255 || Number(replySort.value) < 0 || !num.test(replySort.value)) {
                    sortMessage.value = L["值应为0~255的整数值"]
                    isPreventClick.value = false
                    return
                } else if (!replySort.value.toString()) {
                    sortMessage.value = L["排序值不为空"]
                    isPreventClick.value = false
                    return
                }

                let isShow = switchFlag.value ? 1 : 0

                if (addOrEdit.value == 0) {
                    let params = {
                        msgContent: autoReplyContent.value,
                        sort: replySort.value,
                        isShow: isShow
                    }
                    proxy.$post(`v3/helpdesk/${identity}/auto/add`, params).then(res => {
                        if (res.state == 200) {
                            ElMessage.success(res.msg)
                            getAutoReplyList()
                            dialogVisible.value = false
                        } else {
                            ElMessage(res.msg)
                            isPreventClick.value = false

                        }
                    })

                } else if (addOrEdit.value == 1) {
                    let params = {
                        autoMsgId: msgId.value,
                        isShow: isShow,
                        msgContent: autoReplyContent.value,
                        sort: replySort.value
                    }
                    proxy.$post(`v3/helpdesk/${identity}/auto/update`, params).then(res => {
                        if (res.state == 200) {
                            ElMessage.success(res.msg)
                            getAutoReplyList()
                            dialogVisible.value = false
                        } else {
                            isPreventClick.value = false
                        }
                    })
                }


            }
            //确认end
            const watchContent = (type) => {
                if (type == 0 && autoReplyContent.value) {
                    contentMessage.value = ""
                } else if (type == 1 && replySort.value && replySort.value < 255) {
                    sortMessage.value = ""
                }
            }

            //改变开关或者删除start
            const operateContent = (type, autoItem) => {
                autoItem.isShow = !autoItem.isShow
                let isShowFlag = !autoItem.isShow ? 1 : 0
                let params = {
                    autoMsgId: autoItem.autoMsgId,
                }
                if (type == 1) {
                    params.isShow = isShowFlag
                    proxy.$post(`v3/helpdesk/${identity}/auto/isShow`, params).then(res => {
                        if (res.state == 200) {
                            ElMessage.success(L['修改成功'])
                            getAutoReplyList()
                        } else {
                            ElMessage(res.msg)
                        }
                    })
                } else if (type == 0) {
                    proxy.$post(`v3/helpdesk/${identity}/auto/delete`, params).then(res => {
                        if (res.state == 200) {
                            ElMessage.success(L['删除成功'])
                            getAutoReplyList()
                        } else {
                            ElMessage(res.msg)
                        }
                    })
                }

            }
            //改变开关或者删除end


            //打开对话框（编辑内容）
            const openEdit = (item) => {
                dialogTitle.value = L["编辑欢迎语回复"]
                addOrEdit.value = 1
                dialogVisible.value = true
                msgId.value = item.autoMsgId
                autoReplyContent.value = item.msgContent
                replySort.value = item.sort
                switchFlag.value = item.isShow
                isPreventClick.value = false
            }


            //打开对话框（添加内容）
            const openAdd = () => {
                dialogTitle.value = L["添加欢迎语回复"]
                addOrEdit.value = 0
                autoReplyContent.value = ''
                replySort.value = ''
                switchFlag.value = false
                dialogVisible.value = true
                isPreventClick.value = false
            }


            const dialogClosed = () => {
                isPreventClick.value = false
            }

            onMounted(() => {
                getAutoReplyList()
            })

            return {
                dialogVisible,
                autoReplyContent,
                replySort,
                autoReplyList,
                page,
                handlePrevCilickChange,
                handleNextCilickChange,
                handleCurrentChange,
                switchFlag,
                confirm,
                operateContent,
                openEdit,
                openAdd,
                dialogTitle,
                contentMessage,
                sortMessage,
                watchContent,
                firstLoading,
                L,
                dialogClosed
            }
        }
    }
</script>

<style lang="scss">
    .el-table th>.cell {
        text-align: center;
    }

    .el-table .cell {
        display: flex;
        justify-content: center;
    }

    .el-table__empty-block {
        display: none;
    }

    button {
        padding: 4px 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 13px;
        margin-left: 10px;
        border-radius: 4px;
        background: #FFF;
        border: 1px solid #DCDFE6;
        color: #606266;
        white-space: nowrap;

        &:hover {
            border: 1px solid $colorMain;
            color: $colorMain;
        }
    }



    .sld_auto_reply {
        .top_header {
            width: 100%;
            height: 59px;
            border-bottom: 1px solid #F6F6F6;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .header_title {
                padding-left: 10px;
                border-left: 4px solid $colorMain;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #333333;
            }

            button {
                color: #fff;
                padding: 11px;
                background-color: $colorMain;
                border-radius: 3px;
                border: none;
                margin-right: 15px;
                font-size: 14px;
                font-family: Microsoft YaHei;
            }
        }

        .top_bar {
            padding: 13px 0;
            background-color: #eee;
            display: flex;
            font-size: 15px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            flex-shrink: 1;


            .number_title {
                /* padding: 0 90px; */
                width: 210px;
                text-align: center;
            }

            .reply_title {
                /* padding: 0 190px; */
                width: 455px;
                text-align: center;
            }

            .sort_title {
                width: 310px;
                text-align: center;
                /* padding: 0 140px; */
            }

            .default_use_title {
                /* padding: 0 140px; */
                width: 340px;
                text-align: center;
            }

            .operate_title {
                /* padding: 0 140px; */
                width: 310px;
                text-align: center;
            }
        }

        .container {
            height: 710px;

            .con_item {
                width: 99%;
                display: flex;
                height: 50px;
                align-items: center;
                border-bottom: 1px solid #f2f2f2;
                flex-shrink: 0;

                .number {
                    width: 210px;
                    text-align: center;
                }

                .sort {
                    text-align: center;
                    width: 310px;
                }

                .default_use {
                    padding: 0 150px;
                }

                .operate {
                    width: 310px;
                    display: flex;
                    justify-content: center;

                    button {
                        padding: 4px 15px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        font-size: 13px;
                        margin-left: 10px;
                        border-radius: 4px;
                        background: #FFF;
                        border: 1px solid #DCDFE6;
                        color: #606266;

                        &:hover {
                            border: 1px solid $colorMain;
                            color: $colorMain;
                        }
                    }
                }

                .content {
                    width: 455px;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    padding-left: 10px;
                    padding-right: 10px;
                }

            }
        }

        .sld_pagination {
            margin-top: 20px;
        }

        .el-dialog {
            border-radius: 10px;
        }

        .el-dialog__header {
            background: #409Eff;
            border-top-right-radius: 9px;
            border-top-left-radius: 9px;
            height: 40px;
            padding: 11px 20px 10px;
        }

        .el-dialog__headerbtn {
            top: 13px;
        }

        .el-dialog__title {
            color: #fff;
        }

        .el-dialog__headerbtn .el-dialog__close {
            color: #fff;
        }

        .el-button {
            padding: 7px 19px;
        }

        #popTable {
            margin: 0 auto;
            border-spacing: 10px;

            .title {
                text-align: right;
            }

            .tip {
                color: #C0C4CC;
            }

            i {
                color: #e2231a;
                font-style: normal;
            }

            .message {
                font-size: 12px;
                line-height: 2;
                padding-top: 4px;
                color: #e2231a;
                font-family: Microsoft YaHei;
            }

            td {
                max-width: 284px;

                &.title {
                    padding-top: 9px;
                }
            }
        }
    }

    .empty_auto {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 200px;

        p {
            color: #999999;
            font-size: 14px;
            margin-left: 10px;
        }
    }

    .el-textarea__inner {
        overflow: auto;
    }
</style>